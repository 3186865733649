import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IOrganization } from '@/shared/model/organization.model';
import { ResourceInterface } from '@/shared/component/resource.interface';

const baseApiUrl = 'api/private/organizations';

export default class OrganizationService implements ResourceInterface {
  public find(id: number): Promise<IOrganization> {
    return new Promise<IOrganization>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${Number(id)}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieveSso(name?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/public/organizations/sso?name=${encodeURIComponent(name)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieveSsoByDomainName(name?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/public/organizations/sso/domain?name=${encodeURIComponent(name)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public export(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/export?${buildPaginationQueryOpts(paginationQuery)}`,  {responseType: 'blob'})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IOrganization): Promise<IOrganization> {
    return new Promise<IOrganization>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: IOrganization): Promise<IOrganization> {
    return new Promise<IOrganization>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public resellerUpdate(entities: IOrganization[]): Promise<IOrganization> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/reseller/import`, entities)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getOwner(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}/owner`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getHybridScormViewerCount(dto: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/hybrid-scorm/count', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getHybridScormViewer(dto: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/hybrid-scorm', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getCVRViewer(dto: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/cvr/viewers', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  public getAllHybridScormViewerCount(dto: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/hybrid-scorm/all', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  public getSubscriptionsCount(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}/subscriptions/count`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public isTTADomainAvailable(name: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/tta-domain`, { domain: name })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
